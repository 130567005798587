import React from "react";
import styled from "styled-components";
import { space, rem } from "../design-system";
import { fieldCss } from "./Form";

const Container = styled.div`
  position: relative;
  color: #4a5568;
`;
const Input = styled.select`
  ${fieldCss};
  padding: ${rem(space(200))} ${rem(space(500))} ${rem(space(200))}
    ${rem(space(300))};
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 ${rem(space(200))};
  color: #4a5568;
  pointer-events: none;

  & svg {
    width: ${rem(space(400))};
    height: ${rem(space(400))};
    vertical-align: middle;
  }
`;

function Select(props) {
  return (
    <Container>
      <Input {...props} />
      <Icon>
        <svg
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </Icon>
    </Container>
  );
}

Select.Option = (props) => <option {...props} />;

export default Select;
