import React from "react";
import {
  space,
  rem,
  fontSize,
  elevation,
  shadowOutline,
} from "../design-system";
import styled, { css } from "styled-components";

export const fieldCss = css`
  appearance: none;
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  padding: ${rem(space(200))} ${rem(space(300))};
  color: #4a5568;
  background: white;
  border: 1px solid #e2e8f0;
  ${elevation()};

  &:focus {
    outline: 0;
    ${shadowOutline()};
  }
`;
export const Form = styled.form`
  padding: 0;
`;
export const FormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const Section = styled.section`
  padding: ${rem(space(600))} 0;
  border-top: 1px solid lightgray;

  @media screen and (min-width: 960px) {
    display: flex;
  }
`;
const SectionHeader = styled.header`
  @media screen and (min-width: 960px) {
    width: calc(100% / 5 * 2);
    flex-shrink: 0;
  }
`;
const SectionTitle = styled.h1`
  font-size: ${rem(fontSize(400))};
  margin-bottom: ${rem(space(300))};
`;
export const SectionFields = styled.div`
  width: 100%;
`;
const InputWrapper = styled.div`
  display: block;
  margin-bottom: ${rem(space(400))};

  @media screen and (min-width: 600px) {
    margin-bottom: ${rem(space(500))};
  }
`;
export const InputLabel = styled.label`
  display: inline-block;
  margin-bottom: ${rem(space(200))};
`;
export const Input = styled.input`
  ${fieldCss};
  height: 37px;
`;
export const TextArea = styled.textarea`
  ${fieldCss};
`;
export const FormFooter = styled.footer`
  margin: ${rem(space(700))} 0;
  text-align: center;
`;
export const InputRow = styled.div`
  @media screen and (min-width: 480px) {
    display: flex;

    & ${InputWrapper}:not(:last-child) {
      margin-right: ${rem(space(300))};
    }

    & ${InputWrapper} {
      flex: 1;
    }
  }
`;

export function FormSection({ title, description, children }) {
  return (
    <Section>
      <SectionHeader>
        {title && <SectionTitle>{title}</SectionTitle>}
        {description}
      </SectionHeader>
      <SectionFields>{children}</SectionFields>
    </Section>
  );
}

export function FormField({ label, children }) {
  const id = React.Children.toArray(children).find(({ props }) => props.id)
    ?.props?.id;
  return (
    <InputWrapper>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      {children}
    </InputWrapper>
  );
}
