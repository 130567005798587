import React, {
  useReducer,
  useEffect,
  useContext,
  createContext,
  useMemo
} from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Select from "../components/Select";
import Button from "../components/Button";
import {
  Form,
  FormHeader,
  FormSection,
  FormField,
  Input,
  InputRow,
  FormFooter
} from "../components/Form";
import P from "../components/P";

const defaultState = {
  values: {
    fullName: "",
    maritalStatus: "",
    dateOfBirth: "",
    ssn: "", // Not persisted
    spouseFullName: "",
    spouseDateOfBirth: "",
    spouseSsn: "", // Not persisted
    propertyType: "",
    numOccupants: "",
    occupantsAges: "",
    pets: "",
    presentAddress: "",
    presentCity: "",
    presentState: "",
    presentPhone: "",
    presentAddressLength: "",
    presentRenting: "Yes",
    presentLandlord: "",
    presentLandlordPhone: "",
    presentRentAmount: "",
    employerName: "",
    employerPhone: "",
    employmentLength: "",
    incomePerMonth: "",
    spouseEmployerName: "",
    spouseEmployerPhone: "",
    spouseEmploymentLength: "",
    spouseIncomePerMonth: "",
    relative1Name: "",
    relative1Address: "",
    relative1City: "",
    relative1State: "",
    relative1Phone: "",
    relative2Name: "",
    relative2Address: "",
    relative2City: "",
    relative2State: "",
    relative2Phone: ""
  }
};

function reducer(state, action) {
  switch (action.type) {
    case "FIELD_CHANGED":
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value
        }
      };
    case "RESET":
      return defaultState;
    default:
      return state;
  }
}

const FormStateContext = createContext([defaultState, reducer]);

function ApplicationFormInput({ type = "text", id, ...rest }) {
  const [state, dispatch] = useContext(FormStateContext);

  return (
    <Input
      {...rest}
      type={type}
      id={id}
      name={id}
      value={state.values[id]}
      onChange={({ target }) => {
        dispatch({ type: "FIELD_CHANGED", id, value: target.value });
      }}
    />
  );
}

function ApplicationFormSelect({ id, children, required }) {
  const [state, dispatch] = useContext(FormStateContext);

  return (
    <Select
      id={id}
      name={id}
      value={state.values[id]}
      required={required}
      onChange={({ target }) => {
        dispatch({ type: "FIELD_CHANGED", id, value: target.value });
      }}
    >
      {children}
    </Select>
  );
}

function ApplicationFormDateInput(props) {
  const now = new Date();
  return (
    <ApplicationFormInput
      {...props}
      type="date"
      min={`${now.getFullYear() - 100}-${
        now.getMonth() < 9 ? 0 : ""
      }${now.getMonth() + 1}-${now.getDate()}`}
      max={`${now.getFullYear()}-${
        now.getMonth() < 9 ? 0 : ""
      }${now.getMonth() + 1}-${now.getDate()}`}
    />
  );
}

const InitialsInput = styled(ApplicationFormInput)`
  max-width: 100px;
`;

const getUnsubmittedApplicationData = () => {
  try {
    const storedData = sessionStorage.getItem("unsubmitted-application-data");
    return JSON.parse(storedData);
  } catch (error) {
    return {};
  }
};

function ApplicationPage() {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    values: useMemo(getUnsubmittedApplicationData, []) || defaultState.values
  });
  const isMarried = state.values.maritalStatus === "Married";

  useEffect(() => {
    let values = { ...state.values };
    delete values.ssn;
    delete values.spouseSsn;
    delete values.initials;
    sessionStorage.setItem(
      "unsubmitted-application-data",
      JSON.stringify(values)
    );
  }, [state.values]);

  return (
    <Layout>
      <SEO title="Application" />
      <FormStateContext.Provider
        value={useMemo(() => [state, dispatch], [state, dispatch])}
      >
        <Form
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="Application"
          method="POST"
          action="/application-submitted"
          onSubmit={() => {
            sessionStorage.removeItem("unsubmitted-application-data");
          }}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Application" />
          <FormHeader>
            <h1>Application</h1>
            <input
              type="reset"
              onClick={() => {
                dispatch({ type: "RESET" });
              }}
            />
          </FormHeader>
          <FormSection title="Your Information">
            <FormField label="Full Name*">
              <ApplicationFormInput id="fullName" required />
            </FormField>
            <InputRow>
              <FormField label="Date of Birth*">
                <ApplicationFormDateInput id="dateOfBirth" required />
              </FormField>
              <FormField label="SSN*">
                <ApplicationFormInput id="ssn" required />
              </FormField>
            </InputRow>
            <FormField label="Marital Status*">
              <ApplicationFormSelect id="maritalStatus" required>
                <Select.Option>Single</Select.Option>
                <Select.Option>Married</Select.Option>
                <Select.Option>Separated</Select.Option>
                <Select.Option>Divorced</Select.Option>
              </ApplicationFormSelect>
            </FormField>
          </FormSection>
          {isMarried && (
            <FormSection title="Spouse's Information">
              <FormField label="Full Name*">
                <ApplicationFormInput id="spouseFullName" required />
              </FormField>
              <InputRow>
                <FormField label="Date of Birth*">
                  <ApplicationFormDateInput id="spouseDateOfBirth" required />
                </FormField>
                <FormField label="SSN*">
                  <ApplicationFormInput id="spouseSsn" required />
                </FormField>
              </InputRow>
            </FormSection>
          )}
          <FormSection title="Property">
            <FormField label="Property Type*">
              <ApplicationFormSelect id="propertyType" required>
                <Select.Option>Duplex</Select.Option>
                <Select.Option>Apartment</Select.Option>
                <Select.Option>Office</Select.Option>
              </ApplicationFormSelect>
            </FormField>
            <FormField label="Number of persons to occupy*">
              <ApplicationFormInput id="numOccupants" type="number" required />
            </FormField>
            <FormField label="Ages of occupants*">
              <ApplicationFormInput id="occupantsAges" required />
            </FormField>
            <FormField label="Pets*">
              <ApplicationFormInput id="pets" required />
            </FormField>
          </FormSection>
          <FormSection title="Current Residence">
            <FormField label="Address*">
              <ApplicationFormInput id="presentAddress" required />
            </FormField>
            <InputRow>
              <FormField label="City*">
                <ApplicationFormInput id="presentCity" required />
              </FormField>
              <FormField label="State*">
                <ApplicationFormInput id="presentState" required />
              </FormField>
            </InputRow>
            <FormField label="Phone Number*">
              <ApplicationFormInput id="presentPhone" type="tel" required />
            </FormField>
            <FormField label="How long have you lived here?*">
              <ApplicationFormInput id="presentAddressLength" required />
            </FormField>
            <FormField label="Are you renting?">
              <ApplicationFormSelect id="presentRenting">
                <Select.Option>Yes</Select.Option>
                <Select.Option>No</Select.Option>
              </ApplicationFormSelect>
            </FormField>
            {state.values.presentRenting === "Yes" && (
              <>
                <FormField label="Landlord's Name*">
                  <ApplicationFormInput id="presentLandlord" required />
                </FormField>
                <FormField label="Phone Number*">
                  <ApplicationFormInput
                    id="presentLandlordPhone"
                    type="tel"
                    required
                  />
                </FormField>
                <FormField label="Rent Amount ($)">
                  <ApplicationFormInput id="presentRentAmount" />
                </FormField>
              </>
            )}
          </FormSection>
          <FormSection title="Your Employment">
            <FormField label="Current Employer*">
              <ApplicationFormInput id="employerName" required />
            </FormField>
            <FormField label="Phone Number*">
              <ApplicationFormInput id="employerPhone" type="tel" required />
            </FormField>
            <FormField label="How long have you been employed there?*">
              <ApplicationFormInput id="employmentLength" required />
            </FormField>
            <FormField label="Monthly Income ($)*">
              <ApplicationFormInput id="incomePerMonth" required />
            </FormField>
          </FormSection>
          {isMarried && (
            <FormSection title="Spouse's Employment">
              <FormField label="Current Employer*">
                <ApplicationFormInput id="spouseEmployerName" required />
              </FormField>
              <FormField label="Phone Number*">
                <ApplicationFormInput
                  id="spouseEmployerPhone"
                  type="tel"
                  required
                />
              </FormField>
              <FormField label="How long have they been employed here?*">
                <ApplicationFormInput id="spouseEmploymentLength" required />
              </FormField>
              <FormField label="Income Per Month ($)*">
                <ApplicationFormInput id="spouseIncomePerMonth" required />
              </FormField>
            </FormSection>
          )}
          <FormSection title="Emergency Contact">
            <FormField label="Full Name*">
              <ApplicationFormInput id="relative1Name" required />
            </FormField>
            <FormField label="Address*">
              <ApplicationFormInput id="relative1Address" required />
            </FormField>
            <InputRow>
              <FormField label="City">
                <ApplicationFormInput id="relative1City" />
              </FormField>
              <FormField label="State">
                <ApplicationFormInput id="relative1State" />
              </FormField>
            </InputRow>
            <FormField label="Phone Number">
              <ApplicationFormInput id="relative1Phone" type="tel" />
            </FormField>
          </FormSection>
          <FormSection title="Secondary Emegency Contact">
            <FormField label="Full Name*">
              <ApplicationFormInput id="relative2Name" required />
            </FormField>
            <FormField label="Address*">
              <ApplicationFormInput id="relative2Address" required />
            </FormField>
            <InputRow>
              <FormField label="City">
                <ApplicationFormInput id="relative2City" />
              </FormField>
              <FormField label="State">
                <ApplicationFormInput id="relative2State" />
              </FormField>
            </InputRow>
            <FormField label="Phone Number">
              <ApplicationFormInput id="relative2Phone" type="tel" />
            </FormField>
          </FormSection>
          <FormSection title="Agreement">
            <P>
              I hereby authorize Burch Properties to check with the above
              references to verify rent, credit, and employment history.
            </P>
            <FormField label="Initials*">
              <InitialsInput id="initials" required />
            </FormField>
          </FormSection>
          <FormFooter>
            <Button>Submit Application</Button>
          </FormFooter>
        </Form>
      </FormStateContext.Provider>
    </Layout>
  );
}

export default ApplicationPage;
